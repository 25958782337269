<template>
  <svg
    width="5"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 122"
  >
    <path
      class="cls-1"
      d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,92.93a15,15,0,1,1-15,15,15,15,0,0,1,15-15Zm0-46.47a15,15,0,1,1-15,15,15,15,0,0,1,15-15Z"
      fill="currentColor"
    />
  </svg>
</template>
